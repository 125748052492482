<template>
<div class="col-12">
    <div class="custom-container-box">
        <vs-collapse accordion>
            <vs-collapse-item>
                <div slot="header">
                    <h4 class="custom-header-title">Personalización</h4>
                </div>

                <div class="container mt-4 mb-8">
                    <div class="row">
                        <div class="col-lg-3 col-sm-12 mb-6">
                            <vs-checkbox
                                class="cbCustomTable"
                                v-model="isShowCasoPrueba"
                                @change="$emit( 'showCasoPrueba', isShowCasoPrueba )"
                                >Ver ocultos
                            </vs-checkbox>
                        </div>

                        <div class="col-lg-9 col-sm-12">
                            <v-select 
                                v-model="columnasSelected"
                                multiple 
                                :closeOnSelect="false"
                                :options="columnas" 
                                class="custom-select"
                                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="custom-footer col-md-12"></div> -->

                        <button
                          class="btn btn-second col-xl-3 col-lg-4 col-sm-6 mt-12"
                          @click="insertUpdateColumns(), $emit( 'colsSelected', columnasSelected )"
                        >Guardar configuración</button>

                    </div>
                </div>
            </vs-collapse-item>
        </vs-collapse>
    </div>
</div>    
</template>

<script>
import vSelect  from 'vue-select'

export default {
    components: {
        vSelect 
    },
    props: {
        filterCaso: {
            type: Number,
            required: true
        }
    },
    data() {
		return {
            broker: this.$store.state.AppActiveUser,
            isShowCasoPrueba: false,
            columnas: [
                { label: 'Folio', id: 1 },
                { label: 'Origen', id: 2 },
                { label: 'Fecha ingreso', id: 3 },
                { label: 'Ejecutivo', id: 4 },
                { label: 'Organización', id: 5 },
                { label: 'Tipo de cuenta', id: 6},
                { label: 'Cliente', id: 7 },
                { label: 'Correo', id: 8 },
                { label: 'Teléfono', id: 9 },
                { label: 'Destino', id: 10 },
                { label: 'Valor Inmueble', id: 11 },
                { label: 'Crédito', id: 12 },
                { label: 'Pedido en V3', id: 13 },
                { label: 'Estatus', id: 14 },
                { label: 'Consulta CC', id: 15 },
                { label: 'Precalificación SOC', id: 16 },
                { label: 'Precalificación Scotiabank', id: 17 },
            ],
            columnasSelected: [
                { label: 'Folio', id: 1 },
                { label: 'Origen', id: 2 },
                { label: 'Fecha ingreso', id: 3 },
                { label: 'Ejecutivo', id: 4 },
                { label: 'Organización', id: 5 },
                { label: 'Cliente', id: 7 },
                { label: 'Correo', id: 8 },
                { label: 'Teléfono', id: 9 },
                { label: 'Destino', id: 10 },
                { label: 'Valor Inmueble', id: 11 },
                { label: 'Crédito', id: 12 },
                { label: 'Pedido en V3', id: 13 },
                { label: 'Estatus', id: 14 },
            ],
            columnasZero: [],
            columnasMisCasos: [],
            columnasSupervisados: [],
            columnasDigital: [],
            columnasListZero: [],
            columnasListMisCasos: [],
            columnasListSupervisados: [],
            columnasListDigital: [],
        }
    },
    methods: {
        insertUpdateColumns() {
            let self = this;
            let objInsertUpdateColumns = {
                strApiKey: this.broker.ApiKey,
                strMetodo: 'saveColumnasPersonalizadas',
                objCols: {
                    Columnas: JSON.stringify(this.columnasSelected),
                    Broker: this.broker.ApiKey,
                    FiltroCaso: this.filterCaso,
                    Tabla: 0,
                },
            }

            this.$axios.post('/', objInsertUpdateColumns, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
            }).then( resp => {
                if (resp.data.intEstatus == 0) {
                    this.$vs.notify({
                        title:'Configuración guardada',
                        text:'',
                        color:'success',
                        position:'top-right'
                    });
                } else {
                    this.$vs.notify({
                        title: 'Ocurrio un error al registrar columnas',
                        text: resp.data.strError,
                        color: 'danger',
                        position: 'top-right'
                    });
                }

                // console.log( 'Petición API', resp );
            }).catch(function (error) {
                self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                });
            });
            // console.log( objInsertUpdateColumns );
        },
        sendFatherCols() {
            this.$emit( 'colsSelected', this.columnasSelected );
        },
        loadColsJsonByTblFilte() {
            let self = this;

            // Personalizar lista de opciones por filtro
            switch (this.filterCaso) {
                case 0:
                    this.columnas = this.columnasListZero;
                    break;
                case 1:
                    this.columnas = this.columnasListMisCasos;
                    break;
                case 2:
                    this.columnas = this.columnasListSupervisados;
                    break;
                case 3:
                    this.columnas = this.columnasListDigital;
                    break;
                default:
                    break;
            }

            // Petición al api para ver si existen columnas personalizadas con el broker
            // Objeto enviado al api
            let objSearchColsCompleted = {
                    strApiKey: this.broker.ApiKey,
                    strMetodo: 'getColumnasPersonalizadas',
                    objCols: {
                        Broker: this.broker.ApiKey,
                        FiltroCaso: this.filterCaso,
                        Tabla: 0,
                    },
                }
            // Conexion al API
            this.$axios.post('/', objSearchColsCompleted, { 
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } 
            }).then(
                resp => {
                    if (resp.data.intEstatus == 0) {
                        // Si la petición es diferente de null y devuelve datos obtengo la data de tabla 0
                        if (resp.data.objContenido && resp.data.objContenido.length > 0) {
                            this.columnasSelected = JSON.parse(resp.data.objContenido[0].Columnas);
                            // Emito las columnas obtenidas de bd
                            this.sendFatherCols();
                            // console.log( 'Si hay en BD' );
                            // console.log( 'COLUMNAS BD', this.filterCaso, this.columnasSelected );
                        } else {
                            // Quito etiquetas de las columnas dependiendo del filtro de la tabla
                            switch (this.filterCaso) {
                                case 0:
                                    this.columnasSelected = this.columnasZero;
                                    break;
                                case 1:
                                    this.columnasSelected = this.columnasMisCasos;
                                    break;
                                case 2:
                                    this.columnasSelected = this.columnasSupervisados;
                                    break;
                                case 3:
                                    this.columnasSelected = this.columnasDigital;
                                    break;
                                default:
                                    break;
                            }
                            // console.log( 'No hay en BD' );
                            this.sendFatherCols();
                            // console.log(  'COLUMNAS DEFAULT', this.filterCaso, this.columnasSelected );
                        }
                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error al obtener columnas',
                            text: resp.data.strError,
                            color: 'danger',
                            position: 'top-right'
                        });
                    }
                    // console.log( 'GETDATA', resp.data );
                }
            ).catch(function (error) {
                    self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                });
            });
        },
        filtraColumnasByFiltro() {
            this.columnasZero = [...this.columnasSelected];
            this.columnasMisCasos = [...this.columnasSelected];
            this.columnasSupervisados = [...this.columnasSelected];
            this.columnasDigital = [...this.columnasSelected];

            this.columnasListZero = [...this.columnas];
            this.columnasListMisCasos = [...this.columnas];
            this.columnasListSupervisados = [...this.columnas];
            this.columnasListDigital = [...this.columnas];

            // caso 0
            // Delete: Origen, Pedido en V3, Estatus
            // this.columnasZero = this.columnasZero.filter(item => (item.id != 2 && item.id != 12 && item.id != 13));
            // this.columnasListZero = this.columnasListZero.filter(item => (item.id != 2 && item.id != 12 && item.id != 13));
            // console.log( 'ZERO', this.columnasListZero );

            // caso 1
            // Delete: Origen, Ejecutivo, Estatus
            // this.columnasMisCasos = this.columnasMisCasos.filter(item => (item.id != 2 && item.id != 4 && item.id != 13));
            // this.columnasListMisCasos = this.columnasListMisCasos.filter(item => (item.id != 2 && item.id != 4 && item.id != 13));
            // console.log( 'MIS CASOS', this.columnasListMisCasos );

            // caso 2
            // Delete: Estatus
            // this.columnasSupervisados = this.columnasSupervisados.filter(item => item.id != 13);
            // this.columnasListSupervisados = this.columnasListSupervisados.filter(item => item.id != 13)
            // console.log( 'SUPERVISADO', this.columnasListSupervisados );

            // caso 3
            // Delete: Origen
            // this.columnasDigital = this.columnasDigital.filter(item => item.id != 2);
            // this.columnasListDigital = this.columnasListDigital.filter(item => item.id != 2);
            // console.log( 'DIGITAL', this.columnasListDigital );
        }
    },
    watch: { 
        // Si cambio el filtro cargamos columnas de la bd
      	filterCaso: function(newFilter, oldFilter) { // watch it
        //   console.log('Nuevo: ', newFilter, ' | Anterior: ', oldFilter)
          this.loadColsJsonByTblFilte();
        }
    },
    created() {
        this.filtraColumnasByFiltro();
        this.loadColsJsonByTblFilte();
    },
}
</script>

<style>
.custom-header-title {
    font-size: 1.1rem;
    font-weight: 500;
    text-align: left;
}
.custom-container-box .vs-collapse {
    padding: 10px 0 10px 0;
}
.custom-container-box .vs-collapse-item--header {
    background-color: #f8f9fa;
}
.custom-container-box .vs-collapse-item {
    background-color: #fff;
}
.custom-container-box .con-vs-checkbox {
    justify-content: center !important;
}
.custom-select {
    margin-top: -20px;
}
.custom-select ul {
    max-height: 90px;
}
.custom-select ul>li {
    font-size: 1rem;
}
.custom-footer {
    cursor: default;
    height: 120px;
}
/* @media (min-width: 400px) and (max-width: 897.98px) {
    .custom-container-box {
        margin-top: 75px;
    }
} */
</style>